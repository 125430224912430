export default class SecretTableItem {
    secretId = 0;
    secretStatus = 0;
    accessKey = "";
    secretKey = "";
    userCode = "";
    secretRemark = "";

    userCodeName = "";

    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
